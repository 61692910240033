* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@font-face {
    font-family: 'Argumentum';
    font-style: normal;
    src: url('../src/fonts/ArgumentumLight.ttf');
    src: local('Argumentum'), local('Argumentum'), url('../src/fonts/ArgumentumLight.ttf') format('embedded-opentype'),
}

@font-face {
    font-family: 'Impact';
    font-style: normal;
    src: url('../src/fonts/impact.ttf');
    src: local('Impact'), local('Impact'), url('../src/fonts/impact.ttf') format('embedded-opentype'),
}

@font-face {
    font-family: 'ArgumentumMedium';
    font-style: normal;
    src: url('../src/fonts/ArgumentumMedium.ttf');
    src: local('Argumentum'), local('Argumentum'), url('../src/fonts/ArgumentumMedium.ttf') format('embedded-opentype'),
}

@font-face {
    font-family: 'ArgumentumUltraLight';
    font-style: normal;
    src: url('../src/fonts/ArgumentumUltraLight.ttf');
    src: local('ArgumentumUltraLight'), local('ArgumentumUltraLight'), url('../src/fonts/ArgumentumUltraLight.ttf') format('embedded-opentype'),
}

@font-face {
    font-family: 'ArgumentumLight';
    font-style: normal;
    src: url('../src/fonts/ArgumentumLight.ttf');
    src: local('ArgumentumLight'), local('ArgumentumLight'), url('../src/fonts/ArgumentumLight.ttf') format('embedded-opentype'),
}

body {
  font-family: -apple-system, sans-serif, 'Argumentum';
  position: relative;
  min-height: 100vh;
}

.logo-section {
  height: 79px;
  width: 100%;
  background: #ffffff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding-top: 16px;
}

.logo-box {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.contents {
  width: 549px;
  max-width: 85%;
  height: 100%;
  position: relative;
  margin: auto;
  margin-top: 39px;
  padding-bottom: 60px;
}

@media only screen and (max-width: 400px) {
    .contents {
        max-width: 90%;
    }
}

.contents-back {
  background: #f5f5fe;
  width: 100%;
  height: 100%;
  /* min-height: 600px; */
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.tilted-div {
    -ms-transform: rotate(-6deg);
    transform: rotate(-6deg);
    background: white;
    height: 100px;
    background: white;
    width: 150%;
    position: absolute;
    top: -82px;
    left: -30px;
}

@media only screen and (max-width: 500px) {
    .tilted-div {
        top: -85px;
    }
}

@media only screen and (max-width: 450px) {
    .tilted-div {
        top: -87px;
    }
}

@media only screen and (max-width: 350px) {
    .tilted-div {
        top: -89px;
    }
}

.header {
  font-family: 'impact';
  text-transform: uppercase;
  font-size: 43px;
  padding-top: 5px;
  line-height: 48px;
  color: #313136;
  margin-left: 29px;
}

.sub-header {
  font-family: 'impact';
  color: #313136;
  text-transform: uppercase;
  font-size: 16px;
  padding-top: 24px;
}

.inner-box {
  margin-left: 29px;
  margin-right: 29px;
}

.text-field {
  font-family: Argumentum;
  color: #313136;
  margin-top: 16px;
  font-size: 16px;
  line-height: 27px;
}

.bullet-list {
  margin: 10px;
}

li {
    list-style-type: none;
    position: relative;    /* It's needed for setting position to absolute in the next rule. */
}

li::before {
    content: '•';
    position: absolute;
    left: -12px;          /* Adjust this value so that it appears where you want. */
    font-size: 40px;      /* Adjust this value so that it appears what size you want. */
}

li div {
  padding-left: 8px;
}


.compensation-box {
  background: white;
  padding: 19px;
  margin-top: 30px;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
  overflow: auto;
  position: relative;
}

@media only screen and (max-width: 420px) {
  .compensation-box {
    padding: 10px;
  }
}

.text-btn-left {
  float: left;
  line-height: 50px;
}

.img-btn-right {
  float: right;
  padding-top: 14px;
}

.tick-mark-box {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20%;
  text-align: center;
}

.radio-button-section {
    background: greenyellow;
    position: relative;
    display: block;
    width: 100%;
    margin-top: 20px;
    height: 60px;
}

.radio-button {
  cursor: pointer;
  position: absolute;
}
.radio-button-text {
  left: 50px;
  position: absolute;
}

.button-box {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  /* background: #FFFFFF; */
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
}

.button-box p {
  position: relative;
  height: auto;
  padding-right: 20px;
  left: 30px;
  top: 2px;
}

.check {
  width: 10%;
  position: absolute;
  top: 4px;
}

.compensation-text {
  font-family: Impact;
  text-transform: uppercase;
  line-height: 22px;
  float: right;
  width: 73%;
}

/* .bottom-buttons-box {
    height: 50px;
    display: block;
    margin-top: 30px;
} */

.submit-button {
  margin-top: 30px;
  font-family: Impact;
  text-transform: uppercase;
  width: 100%;
  height: 50px;
  padding-left: 17px;
  padding-right: 17px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  border: none;
  background: #7322c9; 
  color: white;
}

.submit-button:disabled {
  background: #ECE9E8;
  color: #D6D6D6;
  cursor: not-allowed;
}

.go-to-bf-button {
  margin-top: 30px;
  font-family: ArgumentumMedium;
  /* text-transform: uppercase; */
  width: 100%;
  height: 50px;
  text-align: left;
  padding-left: 17px;
  padding-right: 17px;
  font-size: 16px;
  /* White */
  background: #ffffff;
  /* UI/BG */
  border: 1px solid #f5f5fe;
  box-sizing: border-box;
  /* box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); */
  cursor: pointer;
}

.cancel {
  font-family: Impact;
  text-transform: uppercase;
  text-align: center;
  /* background: red; */
  width: 40%;
  margin: auto;
  margin-top: 40px;
}

.error {
  color: #ff7000;
}

/* loader */

.thumb-box {
  margin-top: 24px;
}

.loader-float-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(173, 173, 208, 0.72);
  z-index: 5;
  padding: 16px;
  padding-right: 45px;
  padding-left: 45px;
}

.float-loader {
  text-align: center;
  margin: auto;
  z-index: 1;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  height: 30%;
  max-width: 420px;
  min-width: 240px;
  max-height: 185px;
  min-height: 175px;
  height: 185px;
  width: 100%;
  position: absolute;
  top: 30%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  max-width: 300px;
}

.under-loader-text {
  margin-top: -7%;
  font-family: ArgumentumMedium;
  font-weight: 900;
  color: #fe7000;
  height: 30px;
  font-size: 20px;
  top: 0px;
}

.under-loader-text.with-thump {
  margin-top: 12px;
}

.loader-gif-box {
  padding: 23px;
}

.cursor-pointer {
  cursor: pointer;
}

.loader-box {
  text-align: center;
  width: 100%;
  margin-top: 25px;
}

/* language section: */

.language-section {
  width: 79px;
  height: 25px;
  margin-right: 52px;
  margin-top: 14px;
  position: absolute;
  z-index: 10;
  right: 10px;
}

@media only screen and (max-width: 690px) {
    .language-section {
        right: 0px;
    }
}

@media only screen and (max-width: 620px) {
    .language-section {
        right: -10px;
    }
}

@media only screen and (max-width: 550px) {
    .language-section {
        right: -15px;
    }
}

@media only screen and (max-width: 500px) {
    .language-section {
        right: -20px;
    }
}

@media only screen and (max-width: 400px) {
    .language-section {
        right: -29px;
    }
}

.language-menu-top {
  cursor: pointer;
}

.lang-arrow {
  margin-top: 5px;
}

.lang-row {
  position: relative;
  height: 30px;
  cursor: pointer;
}

.language-short {
  font-family: Impact;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 24px;
  color: #d6d6d6;
  float: left;
  margin-left: 30px;
  margin-right: 3px;
}

.language-short.open {
    color: #313136;
}

.lang-divider {
  background: #d6d6d6;
  height: 1px;
  margin-top: 9px;
  margin-bottom: 8px;
  width: 90px;
  position: absolute;
  right: -5px;
  top: 20px;
}


/* hr {
  height: 1px;
  border-top: 1px solid #D5D5E5 !important;
  border-bottom: 1px solid #D5D5E5; */
    
hr {
  border: none;
  padding: 5px 0;
  border: none;
}

hr:before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    max-width: 100%;
    width: 100%;
    border-top: 1px solid #D5D5E5;
  }

.rotate-up {
    transform: rotate( 180deg);
    transition: transform 400ms ease;
}

.rotate-down {
    transform: rotate( 0deg);
    transition: transform 400ms ease;
}

.lang-section-back {
  background: #ece9e8;
  width: 125px;
  height: 175px;
  position: absolute;
  z-index: -1;
  top: -10px;
  left: -25px;
}

.language-menu-top {
    /* background: hotpink; */
    cursor: pointer;
}

.lang-menu {
    text-align: left;
    right: 50px;
    font-family: ArgumentumMedium, Arial, sans-serif;
    font-size: 16px;
    line-height: 27px;
    width: 79px;
    margin-top: 7px;
    margin-right: 100px;
    animation-name: expand2;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-duration: 0.4s;
}

@media only screen and (max-width: 450px) {
    .lang-menu {
        position: absolute;
        right: -80px;
        top: 52px;
    }
    .lang-section-back {
        left: -42px;
        top: 47px;
        height: 143px;
    }
}

@keyframes expand2 {
    0% {
        max-height: 0px;
    }
    100% {
        max-height: 200px;
    }
}

.orange {
  color: #fe7000;
}



/* UI/Divider */

.divider {
  position: absolute;
  width: 550px;
  height: 0px;
  left: 445px;
  top: 748px;

  border: 1px solid #d5d5e5;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(15, 34, 201, 0.1);
  z-index: 20;
}

.vertically-centered {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.vertically-centered.loader {
  top: 30%;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

